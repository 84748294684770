import { graphql } from 'gatsby';
import React, { FC } from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import styles from './indigoCement.module.scss';
import IndigoTile from '../components/indigo-tile/IndigoTile';
import SizeInfo from '../components/indigo-tile/SizesInfo';
import Back from '../images/moroccan_background.jpg';
import Back2 from '../images/indigo/header-page2.jpg';
import cn from 'classnames';

export const query = graphql`
  {
    strapi {
      guidelinesTextBlocks {
        Title
        Description
      }
      indigoTiles(sort: "SortIndex:asc") {
        Name
        Sizes
        Image {
          url
        }
      }
      indigoTilesSizesInfos(sort: "SortIndex:asc") {
        Name
        Image {
          url
        }
      }
    }
  }
`;

interface Props {
  data: {
    strapi: {
      guidelinesTextBlocks: Array<{
        Title: string;
        Description: string;
      }>;
      indigoTiles: Array<{
        Name: string;
        Sizes: string;
        Image: {
          url: string;
        };
      }>;
      indigoTilesSizesInfos: Array<{
        Name: string;
        Image: {
          url: string;
        };
      }>;
    };
  };
}

const IndigoCement: FC<Props> = ({
  data: {
    strapi: { indigoTiles, indigoTilesSizesInfos, guidelinesTextBlocks },
  },
}: Props) => {
  return (
    <Layout>
      <SEO title="Indigo Cement" />
      <section className={styles.wrapper}>
        <div className={styles.header}>
          <img src={Back2} className={styles.image} />
          <div className={cn(styles.border, styles.borderTop)}></div>
          <div className={cn(styles.titleContainer, styles.titleContainerTop)}>
            <p className={styles.title}>{'Indigo by \n Da Vinci'}</p>
          </div>
        </div>
        <section className={styles.tilesWrapper}>
          <div className={styles.tilesContainer}>
            {indigoTiles.map((item, i) => (
              <div key={i + `${item.Name}`} className={styles.tile}>
                <IndigoTile image={item.Image} name={item.Name} sizes={item.Sizes} />
              </div>
            ))}
          </div>
        </section>
        <section className={styles.tilesWrapper}>
          <SizeInfo data={indigoTilesSizesInfos} />
        </section>
        <div className={styles.header} style={{ marginTop: '-7rem' }}>
          <img src={Back2} className={cn(styles.image, styles.imageMargin)} />
          <div className={styles.border}></div>
          <div className={styles.titleContainer}>
            <p className={styles.title}>{'Indigo by \n Da Vinci'}</p>
          </div>
        </div>
        <section className={styles.tilesWrapper} style={{ paddingTop: 0 }}>
          <p className={styles.textTitle}>Guidelines for Installing</p>
          <div className={styles.guideWrapper}>
            {guidelinesTextBlocks.map((textBlock, i) => (
              <div key={i} className={styles.guideBlock}>
                <p className={styles.guideTitle}>{textBlock.Title}</p>
                <p className={styles.guideDescription}>{textBlock.Description}</p>
              </div>
            ))}
          </div>
        </section>
      </section>
    </Layout>
  );
};

export default IndigoCement;
