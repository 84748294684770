import React, { FC } from 'react';
import styles from './sizesInfo.module.scss';
import cn from 'classnames';

interface Props {
  data: {
    Image: {
      url: string;
    };
    Name: string;
  }[];
}

const SizeInfo: FC<Props> = ({ data }: Props) => {
  return (
    <section className={styles.wrapper}>
      <div className={styles.leftBlock}>
        <p className={styles.title}>Size Information</p>
        <div className={styles.container}>
          {data.map((item, i) => (
            <div key={i} className={styles.blockWrapper} style={{ order: +`${i}` }}>
              {item.Name && <p className={styles.name}>{item.Name}</p>}
              <div className={styles.imageWrapper}>
                {item.Image && (
                  <img
                    src={item.Image.url}
                    alt="image"
                    className={cn(
                      styles.image,
                      { [styles.image1]: i === 0 },
                      { [styles.image2]: i === 2 },
                    )}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.rightBlock}>
        <p className={styles.title}>Other Information</p>
        <p className={styles.text}>
          {'Sold in full boxes only\nSold by square foot\nAll sizes are nominal'}
        </p>
      </div>
    </section>
  );
};

export default SizeInfo;
